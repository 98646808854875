<template>
  <b-card 
    no-body
  >
    <div class="m-2 px-2">
      <b-row>
        <div class="w-100 d-flex flex-wrap align-items-center justify-content-center">
          <img
            class="fade-in-image"
            fluid
            src='https://i.pinimg.com/originals/26/dd/d5/26ddd5fa35f6438a9815acbf67506097.jpg'
            width="270"
            height="180"
          >
        </div>
        <div class="w-100 py-1 text-center">
          <h2> {{ businessCard.user_id }}-{{ businessCard.id }} </h2>
        </div>
      </b-row>

      <b-row>
        <h3> Current Active Profile: {{ businessCard.active_link || '-' }} </h3>
      </b-row>

      <b-row class="mt-1">
        <b-card
          class="w-100"
          border-variant="dark"
        >
          <b-row>
            <b-col
              v-for="link in businessCard.dynamic_link"
              :key="link.key"
              cols="4"
              class="d-flex align-items-center px-2"
            >
              <div class="w-100 d-flex align-items-center">
                <label class="switch m-0 p-0">
                  <input
                    :checked="businessCard.active_link === link"
                    type="checkbox"
                    @click="changeActiveLink(link)"
                  >
                  <span class="slider round" />
                </label>
                <h5 class="m-0 p-0 px-1"> {{ link }} </h5>
              </div>

              <div class="w-100 d-flex align-items-center justify-content-end">
                <span class="m-0 p-0 pl-1">
                  <feather-icon icon="EditIcon" />
                </span>
                <span class="m-0 p-0 pl-1 text-danger">
                  <feather-icon icon="TrashIcon" />
                </span>
              </div>
            </b-col>

          </b-row>
        </b-card>
      </b-row>
    </div>

    <b-modal
      v-model="popupBusinessCardForm"
      centered
      hide-footer
      :title="`${$capitalizeString(formType)} a Business Card`"
      size="xl"
    >
      <business-card-form
        :card-id="cardId"
        :form-type="formType"
        @close-modal="popupBusinessCardForm = !popupBusinessCardForm"
        @refresh-record="getBusinessCard($route.params.id)"
      />
    </b-modal>
  </b-card>
</template>

<script>
import { businessCardFormMixins } from '../mixins/formMixins'

export default {
  mixins: [
    businessCardFormMixins,
  ],
  data() {
    return {
      businessCard: {},
    }
  },
  created() {
    this.getBusinessCard(this.$route.params.id)
  },
  methods: {
    getBusinessCard(id) {
      this.$http
        .get(`/api/business-cards/${id}`)
        .then(response => {
          const { success, message, output } = response.data
          this.businessCard = output
        })
        .catch(error => {
          this.$handleResponseError(error)
        })
    },
    changeActiveLink(link) {
      // alert('change active link')
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
